import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import { LuCheckSquare, LuFolder, LuUser } from 'react-icons/lu';
import { ExternalLinkIcon } from '@chakra-ui/icons';

// import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  VStack,
  HStack,
  UnorderedList,
  ListItem,
  Radio,
  RadioGroup,
  Button,
  useToast,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';

import useNoo from 'hooks/useNoo';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';
import useGroups from 'hooks/useGroups';
import UserAuth from 'components/User/UserAuth';
import OneDecision from 'components/Decisions/OneDecision';
import GenericSelector from 'components/Segments/GenericSelector';
import CopyButton from 'components/Segments/CopyButton';
import markdown from 'shared/config/homepageMarkdowns/ppparty';
import markdown_instructions from 'shared/config/homepageMarkdowns/ppparty_instructions';
import MarkdownAccordion from 'components/Segments/MarkdownAccordion';

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

function useQuery() {
  var params = new URLSearchParams(useLocation().search);
  var lookup = {};
  for (var pair of params.entries()) {
    lookup[pair[0]] = pair[1];
  }
  return lookup;
}
const tabSx = {
  border: '1px solid grey'
};

const Splash = () => {
  const { googleUser: user, currentNetworkData, nooUser } = useNoo('Splash');
  const network = currentNetworkData;

  const [focusRef, setFocusRef] = useState(null);
  const [warned, setWarned] = useState(false);
  const [inviter, setInviter] = useState(null);
  const [inviteGroup, setInviteGroup] = useState(null);
  const inputRef = useRef();
  const accordionRef = useRef();
  const history = useHistory();
  const toast = useToast();
  const [gcId, setGcId] = useState(
    '3409f19e00da809808952a2c8bdf06020fa11ab4d51450bb60dbd052f2b518ef'
  );
  const [topIndex, setTopIndex] = useState(-1);
  const [innerIndex, setInnerIndex] = useState(-1);
  const [, forceUpdate] = useReducer(x => x + 1, 0); // https://stackoverflow.com/questions/46240647/react-how-to-force-to-re-render-a-functional-component/53837442#53837442

  // TODO: if using invitations here, update to useInvitations() hook
  // const onCallAcceptInvitation = () => {
  // 	nooApi.acceptInvitation(user, invitationId, setAcceptLoading, setAcceptResponse);
  // };

  let params = useQuery();

  useEffect(() => {
    if (params.token) {
      const [group, inviter] = params.token.split('|');
      setInviter(inviter);
      setInviteGroup(group);
      // history.replace('/');
    }
  }, [history, inviter, params]);

  useEffect(() => {
    if (inviter && inviteGroup) {
      const key = 'inviters';
      let inviters_by_group = JSON.parse(localStorage.getItem(key) || '{}');
      let current = inviters_by_group[inviteGroup] || [];
      if (!current.includes(inviter)) {
        current.push(inviter);
      }
      inviters_by_group[inviteGroup] = current;
      localStorage.setItem(key, JSON.stringify(inviters_by_group));
    }
  }, [inviteGroup, inviter]);

  const makeLink = () => {
    if (nooUser?.person) {
      const gkey = defaultGroup.replace('Groups/', '');
      const ikey = nooUser.person._id.replace('Persons/', '');
      const token = [gkey, ikey].join('|');
      const link = location.href + '?token=' + token;
      toast({
        title: (
          <HStack>
            <Text>Share this link with others you want to invite</Text>{' '}
            <CopyButton text={link} color='#fff' bgColor='#f00' />
          </HStack>
        ),
        description: link,
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    }
  };
  var invitationId = params.invite;
  const { currentGroupId, setGroupId, groupSelector, currentGroupName } = useGroups({
    from: 'Women splash'
  });
  const defaultDecision = 'Questions/7869d342-7b59-4af1-a298-451c9a9ad2e0';
  const defaultGroup = 'Groups/9baab5f4-ab3a-4814-92fc-711ff2112a0c';
  const [decision, setDecision] = useState(defaultDecision);

  // TBD: InputRef for each decision so we can focus there.

  const display = network?.display;
  const paras = network.welcome;

  const onChangeDecision = dId => {
    setFocusRef(inputRef);
    setDecision(dId);
    setTopIndex(0);
    if (accordionRef?.current) {
      console.log('setting');
      accordionRef.current.index = 0;
      // forceUpdate();
    }
  };
  const boxSx = {
    border: '2px solid #aaa',
    padding: '4px',
    margin: '4px',
    backgroundColor: '#eee',
    width: '75%'
  };
  const whiteBoxSx = {
    border: '1px solid #ccc',
    padding: '4px',
    margin: '4px',
    width: ['95%', '85%', '75%'],
    fontSize: ['12px', '14px', '16px']
  };

  const inquirySx = {
    border: '2px solid #aaa',
    padding: '4px',
    margin: '4px',
    backgroundColor: '#eee'
  };
  const paraSx = {
    mb: '12px',
    fontSize: ['13px', '16px']
  };

  const crowdfund = 'https://opencollective.com/noonao/projects/planetary-women';
  // <Image src={'/public/images/ChildrenModestCover.jpg'} alt='' width={['120px']} />

  const closeAccordions = e => {
    console.log('TOP', e.target, e.target.parentNode);
    setInnerIndex(-1);
  };
  console.log('TOPp', topIndex, accordionRef.current);
  const toggle = e => {
    const ind = e.target.dataset.index;
    console.log('E', ind, e.target);
    setTopIndex(ind);
    // forceUpdate();
    // accordionRef.current.index = ind;
  };

  /*   const fetchGCData = async gcid => {
    const call = 'https://greencheck.world/user?user=' + gcid;
    console.log('opengraph CALL', call);
    const response = await fetch(call);
    // console.log('RESP', response  we);
    const data = await response.json();
    if (data) {
      return { data };
    }
    // not sure we should throw a hard error here?
    // throw new Error('error getting the headline data from opengraph');
    return { data: null, error: 'error getting the headline data from opengraph' };
  };

  const gc_data = useMemo(() => {
    if (gcId) {
      return fetchGCData(gcId);
    } else {
      console.log('no gcid');
    }
    return {};
  }, [gcId]);

  console.log('GCD', gc_data); */

  return (
    <Box>
      <VStack alignItems={'center'}>
        <Text sx={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'bodoni' }}>
          reinventing movement politics, planetarily
        </Text>

        <Accordion
          className='accordion'
          /*  defaultIndex={topIndex} */
          ref={accordionRef}
          allowToggle
          variant='subtle'
          width={['90vw', '82vw', '60vw']}
        >
          <AccordionItem>
            <AccordionButton data-index={0} onClick={toggle}>
              <Heading as='h4' fontSize={'lg'}>
                Action, not despair!
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel sx={{ fontSize: ['14px', '18px'] }}>
              <Text sx={paraSx}>
                Are you a US citizen <strong>no longer proud to be &quot;American&quot;</strong>? Or
                a planetary citizen despairing that{' '}
                <strong>the US is accelerating the Metacrisis</strong>? Instead of wasting psychic
                energy on despair and resistance,{' '}
                <strong>let&apos;s do something &quot;sideways&quot;</strong> that can actually
                work.
              </Text>
              <Text sx={paraSx}>
                The book{' '}
                <em>
                  <Link href='https://www.sup.org/books/politics/children-modest-star' isExternal>
                    Children of a Modest Star: Planetary Thinking for an Age of Crises
                    <ExternalLinkIcon mx='2px' />
                  </Link>
                </em>
                makes a strong case for new <strong>planetary institutions</strong> capable of
                dealing with crises beyond the scope of nation states, e.g. climate and pandemics.
              </Text>
              <Text sx={paraSx}>
                See{' '}
                <Link href='/about'>
                  <strong>
                    <em>About</em>
                  </strong>
                </Link>
                &nbsp; for more detail.
              </Text>
              <Text sx={paraSx}>
                This project aims to create one such institution to address the global crisis of
                &nbsp;<strong>corrupted political systems</strong> incapable of working planetarily.
                We propose the first <strong>planetary political party</strong>
              </Text>
              <Text sx={paraSx}>
                We, the{' '}
                <strong>
                  climate, regeneration, indigenous, bioregional, social justice, women&apos;s
                  movements
                </strong>
                , and adjacent allies who want a{' '}
                <Link
                  isExternal
                  href='https://medium.com/basic-income/can-we-create-a-world-that-works-for-all-if-the-elites-in-charge-don-t-want-us-to-5eed5994c6be'
                >
                  world that works for all.
                  <ExternalLinkIcon mx='2px' />
                </Link>
                , are 100s of Ms strong. Existing tools make it easy to span that cohort through
                networks of trust, respect, and invitation, starting from an{' '}
                <Link href='/about?index=4'>initiating kernel of exemplary humans</Link>.
              </Text>
              <Text sx={paraSx}>
                The <strong>unique capacity of networks for emergent coordination</strong> makes it
                possible to self-organize at scale.
              </Text>

              <Text sx={paraSx}>
                Our goal is to <strong>surpass the size of the US Democratic Party</strong> (45M)
                before the 2026 midterms, and to have more{' '}
                <strong>political, financial, and cultural power</strong> than the Catholic Church.
                Achieving such scale depends largely on{' '}
                <Link href='/about?index=5'>how we approach governance</Link> as it goes through
                phase changes from miniscule to planetary.
              </Text>
              <Text sx={paraSx}>
                You have been invited to be part of that. It only takes a small amount of time and
                effort. See <strong>Instructions</strong> below.
              </Text>
              <Text></Text>
              {/* <MarkdownAccordion markdown={markdown} index={innerIndex} /> */}
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton data-index={1} onClick={toggle}>
              {' '}
              {/* onClick={toggle} */}
              <Heading as='h4' fontSize={'lg'}>
                Instructions
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel sx={{ fontSize: ['14px', '18px'] }}>
              <MarkdownAccordion markdown={markdown_instructions} index={innerIndex} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
      <UserAuth unauthorized='signin'></UserAuth>
      <HStack backgroundColor='cyan.200'>
        <RadioGroup
          sx={{
            border: '1px solid lightgray',
            padding: '0px 4px',
            fontWeight: 'bold'
          }}
          onChange={onChangeDecision}
          defaultValue={defaultDecision}
          size={'lg'}
        >
          <HStack direction='row'>
            <Radio bg='#fff' value={defaultDecision}>
              Shape the Platform
            </Radio>
            <Radio bg='#fff' value={'Questions/b4bb7271-daf4-4679-a12f-961c472b97e4'}>
              Name it
            </Radio>
            <Radio bg='#fff' value={'Questions/330bf97e-f674-4804-b120-7f056062d167'}>
              Tagline{' '}
            </Radio>
            <Radio bg='#fff' value={'Questions/513c4900-9ad7-4333-8460-105f55a2ac98'}>
              Pledge
            </Radio>
          </HStack>
        </RadioGroup>
      </HStack>

      {decision && (
        <Box sx={inquirySx}>
          <OneDecision
            group_in={defaultGroup}
            decision_in={decision}
            focus={focusRef}
            loggedin={nooUser?.person?._id}
          />
        </Box>
      )}
    </Box>
  );
};

export default Splash;
