const markdown = `
### Our ask from you
This is a real-time consensus tool. You only need to do two things, which should take only 5-10 minutes at first. 


* **Participate in the polls** below - Suggest any missing alternatives, and vote for the ones you most value. Help **evolve the platform**, **name it**, and tell us how much you will **pledge** to take it to the nex level.

* **Invite** a few others you think align with the platform so far. We will be providing personalized invitations shortly.

* **Get involved** - If you're interested in participating,  [contact us on Linkedin](https://www.linkedin.com/in/brad-degraf-4521) for now. We'll be starting up a groupware (possibly Slack) soon.

(Coming soon: Voting in the polls will require starting from an invitation link from an existing participant.)


### Expansion by invitation
Each participant gets a unique invitation link (coming shortly) they share with others. Accepting an invitation creates a *relationship edge* from the inviter to the invitee. Such edges provide accountability, delegation, and numerous other capabilities we can take advantage of.

We're starting by leveraging LinkedIn. It has 890M users, including, for instance, 31M who have the word "environment" in their profile.

Starting from the initiating kernel of 100, if each participant invites 25 unique others, we get to over 1M in three hops. Our analytics show, on Linkedin, it's "four degrees of separation", not six. (Of course, non-LinkedIn users are welcome and can be invited in the same way.)



`;

export default markdown;
