import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { LuCheckSquare, LuFolder, LuUser } from 'react-icons/lu';
import { ExternalLinkIcon } from '@chakra-ui/icons';

// import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Link,
  Text,
  VStack,
  HStack,
  UnorderedList,
  ListItem,
  Radio,
  RadioGroup,
  Button,
  useToast
} from '@chakra-ui/react';

import SelectorExtendable from 'components/Segments/SelectorExtendable';
// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

const tabSx = {
  border: '1px solid grey'
};

const Madlibs = () => {
  const TextInline = styled(Text)`
    display: inline;
  `;

  const defaultWords = () => {
    const keys = 'name what that who can important'.split(' ');
    let def = {};
    keys.forEach(one => {
      def[one] = '_______';
    });
    return def;
  };
  const [words, setWords] = useState(defaultWords);
  const [sentences, setSentences] = useState([]);
  const [results, setResults] = useState([]);

  const resultsRef = useRef();
  const textCallback = data => {
    let current = words;
    current[data.name] = data.selected;
    setWords(current);
  };

  const Sentence = text => {
    return <HStack>text</HStack>;
  };

  const Atom = text => {
    return <Text>{text}</Text>;
  };

  const printMadlib = useCallback(() => {
    const atoms = [
      words.name,
      'is a',
      words.what,
      'that',
      words.that,
      '. Because of it,',
      words.who,
      'can',
      words.can,
      '. This is important because',
      words.important
    ];
    const sentence = atoms.join(' ');
    /*  const sentence = Object.keys(words)
      .map((k, i) => <Atom key={i}>{words[k]}</Atom>)
      .join('|'); */
    let current = sentences;
    current.push(sentence);
    setSentences(current);
    let out = [];
    current.forEach((sent, index) => {
      out.push(<Text key={index}>{sent}</Text>);
    });
    setResults(out);
  }, [sentences, words]);

  return (
    <Box>
      <Heading as='h3' size='md' fontWeight='bold' textAlign='center' mb='1em'>
        Madlibs
      </Heading>
      <Box>
        <Heading sx={{ fontSize: '24px' }}>Example</Heading>
        <Text>
          <strong>Apple</strong> is a <strong>technology company</strong> that{' '}
          <strong>creates hardware, software and services</strong>. Because of it,{' '}
          <strong>people at all levels of expertise</strong> can{' '}
          <strong>easily access, understand and utilize technology</strong>. This is important
          because{' '}
          <strong>
            lowering the barriers to entry for engaging with technology means more people have
            access to the opportunities it can afford
          </strong>
          .
        </Text>
      </Box>
      <Heading sx={{ fontSize: '24px' }}>Fill in the blanks.</Heading>
      <Heading sx={{ fontSize: '18px' }}>
        {' '}
        Then Print. Repeat with different options. Copy results at the bottom to a shared decument
      </Heading>
      <HStack sx={{ display: 'inline', lineHeight: '2', marginBottom: '20px' }}>
        <SelectorExtendable cb={textCallback} name='name' />
        <TextInline> is a </TextInline>
        <SelectorExtendable cb={textCallback} name='what' />
        <br></br> <TextInline>that</TextInline> <SelectorExtendable cb={textCallback} name='that' />
        <TextInline>
          .<br></br> Because of it,{' '}
        </TextInline>
        <SelectorExtendable cb={textCallback} name='who' />
        <TextInline> can </TextInline>
        <SelectorExtendable cb={textCallback} name='can' />
        <TextInline>
          .<br></br> This is important because{' '}
        </TextInline>
        <SelectorExtendable cb={textCallback} name='important' />
        <TextInline>.</TextInline>{' '}
      </HStack>
      <Button onClick={printMadlib}>Print</Button>
      {results && <VStack ref={resultsRef}>{results}</VStack>}
    </Box>
  );
};

export default Madlibs;
