import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ChakraProvider from './ChakraProvider';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Admin from 'components/Admin/Admin';
import Header from 'components/Segments/Header';
import Nav from 'components/Segments/TopMenu';
import Account from 'components/User/Account';
import InviteRich from 'components/Invitations/InviteRich';
// import InviteFriends from 'components/Invitations/InviteFriends';
import ComposeNetworks from 'components/Search/ComposeNetworks';
// import Invite from 'components/Invitations/Invite';
import InviteAccept from 'components/Invitations/InviteAccept';
import InviteUrlProcessor from 'components/Invitations/InviteUrlProcessor';
import Share from 'components/Invitations/Share';
import Splash from 'components/Segments/Splash';
import About from 'components/Segments/About';
import ShowGroup from 'components/Networks/ShowGroup';
import ShowRespect from 'components/Networks/ShowRespect';
import UserProfile from 'components/Networks/UserProfile';
import ClaimProfile from 'components/Networks/ClaimProfile';
import AddListing from 'components/Listings/AddListing';
import Listing from 'components/Listings/Listing';
import Decision from 'components/DecisionsOld/Decision';
import SearchListings from 'components/Search/SearchListings';
import SearchDecisionsOld from 'components/Search/SearchDecisionsOld';
import DecisionsModule from 'components/Decisions/DecisionModule';
import TreemapModule from 'components/Decisions/TreemapModule';
import OneEarthModule from 'components/Decisions/OneEarthModule';
import BioregionsModule from 'components/DataViz/BioregionsModule';
import CommunityModule from 'components/Networks/CommunityModule';
import SearchHeadlines from 'components/Search/SearchHeadlines';
import SearchBookmarks from 'components/Search/SearchBookmarks';
import SearchPenumbra from 'components/Search/SearchPenumbra';
import Timeline from 'components/Search/Timeline';
// import SearchNetwork from 'components/Search/SearchNetwork';
import Ontology from 'components/Segments/Ontology';
import Markdown from 'components/Segments/Markdown';
import AccountFAN from 'components/User/AccountFAN';
import FanClaim from 'components/User/FanClaim';
import Madlibs from 'components/SplashPages/Madlibs';
import Positioning from 'components/Segments/Positioning';

// import Discussion from 'components/Segments/Discussion';
import Chat from 'components/Segments/Chat';
// import BuildNetwork from 'components/Networks/BuildNetwork'; // alternative to SearchNetwork, allows add subgroup
import Register from 'components/User/Register';
// import Footer from 'components/Segments/Footer';
import Test from 'components/Test';
import useNoo from './hooks/useNoo';
import useMultiDomain from 'hooks/useMultiDomain';
import bootstap from 'hooks/useBootstap';

import Demo from 'components/Demo/index';
import Import from 'components/Segments/Import';

import './base2.scss';
import VennCommunities from './components/Networks/VennCommunities';

const AppContainer = styled(Box)`
  max-width: 100%;
`;

const App = () => {
  const { isAdmin, currentNetwork } = useNoo('App');
  const { HomeRouteComponent, domainAppConfig } = useMultiDomain();

  const HomeRoute = useMemo(() => {
    const host = currentNetwork + '.noo.network';
    const currentConfig = domainAppConfig(host);
    const currentHomeComponent = currentConfig?.HomeRouteComponent;
    if (currentHomeComponent) {
      return currentHomeComponent;
    } else if (HomeRouteComponent) {
      return HomeRouteComponent;
    }
    // fallback default just in case:
    return Splash;
  }, [HomeRouteComponent, currentNetwork, domainAppConfig]);

  // TODO: make a bootstrap function of some kind that we can run once and set up the basics,
  // currently spread around a lot across different parts
  const { domainConfig } = useMultiDomain();
  const { setCurrentNetwork } = useNoo();

  useEffect(() => {
    bootstap({ domainConfig, setCurrentNetwork });
  }, [domainConfig, setCurrentNetwork]);

  // NOTE: can explore this setting for NAV > Box width=['96%', '80%', '60%']
  // this used to be embedded in all the <Container> components in the route roots

  return (
    <ChakraProvider>
      <Router>
        <AppContainer width={['100%', '96%', '95%']} m='0 auto'>
          <Header />
          <Nav />
          <Box margin='auto' width={['100%', '100%', '100%']}>
            <Switch>
              <Route path='/register'>
                <Register />
              </Route>
              {isAdmin && (
                <Route path='/admin'>
                  <Admin />
                </Route>
              )}
              <Route path='/add_listing/:listing_id?'>
                <AddListing />
              </Route>
              <Route path='/search_listings'>
                <SearchListings />
              </Route>
              <Route path='/search_decisions-old/:group_id?'>
                <SearchDecisionsOld />
              </Route>
              <Route path='/search_decisions/:groupId?/:decisionId?'>
                <DecisionsModule />
              </Route>
              <Route path='/wikibudget/:groupId?/:decisionId?'>
                <TreemapModule />
              </Route>
              <Route path='/oneearth'>
                <OneEarthModule />
              </Route>
              <Route path='/bioregions'>
                <BioregionsModule />
              </Route>
              <Route path='/community/:groupId?'>
                <CommunityModule />
              </Route>
              <Route path='/venn_community'>
                <VennCommunities />
              </Route>
              <Route path='/bookmarks/:params?'>
                <SearchBookmarks />
              </Route>
              <Route path='/timeline/:params?'>
                <Timeline />
              </Route>
              <Route path='/headlines'>
                <SearchHeadlines />
              </Route>
              <Route path='/search_network'>
                <ShowGroup />
              </Route>
              <Route path='/respect'>
                <ShowRespect />
              </Route>
              <Route path='/ontology'>
                <Ontology />
              </Route>
              <Route path='/group/:group_id?'>
                <ShowGroup />
              </Route>
              <Route path='/user/:person_id?'>
                <UserProfile />
              </Route>
              <Route path='/markdown/:page?'>
                <Markdown />
              </Route>
              <Route path='/account'>
                <Account />
              </Route>
              <Route path='/claim'>
                <ClaimProfile />
              </Route>
              <Route path='/invite/accept'>
                <InviteAccept />
              </Route>
              <Route path='/invite/:params?'>
                <InviteRich />
              </Route>
              <Route path='/invite_bulk'>
                <ComposeNetworks />
              </Route>
              <Route path='/share/:url?'>
                <Share />
              </Route>
              <Route path='/discussion'>
                <Chat />
              </Route>
              <Route path='/i/:bundle/:inviteId'>
                <InviteUrlProcessor />
              </Route>
              <Route path='/n/:bundle'>
                <InviteUrlProcessor />
              </Route>
              <Route path='/listing/:listing_id/:person_id'>
                <Listing />
              </Route>
              <Route path='/decision-old/:decision_key'>
                <Decision />
              </Route>
              <Route path='/test/:origin?'>
                <Test />
              </Route>
              <Route path='/about/:index?'>
                <About />
              </Route>
              <Route path='/demo'>
                <Demo />
              </Route>
              <Route path='/penumbra/:depth?/:pids?'>
                <SearchPenumbra />
              </Route>
              <Route path='/import'>
                <Import />
              </Route>
              <Route path='/fan'>
                <AccountFAN />
              </Route>
              <Route path='/fan-claim/:ott'>
                <FanClaim />
              </Route>
              <Route path='/madlibs'>
                <Madlibs />
              </Route>
              <Route path='/positioning'>
                <Positioning />
              </Route>
              <Route path='/'>
                <HomeRoute />
              </Route>
            </Switch>
          </Box>
        </AppContainer>
      </Router>
    </ChakraProvider>
  );
};

export default App;
