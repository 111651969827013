import React, { useState } from 'react';
import {} from 'react-router-dom';
import { Box, Text, HStack, Radio, RadioGroup } from '@chakra-ui/react';
import UserAuth from 'components/User/UserAuth';
import OneDecision from 'components/Decisions/OneDecision';

function Positioning(props) {
  const defaultDecision = 'Questions/3495f3bd-4c05-4dce-80c7-26dd9c91f9d2';
  const defaultGroup = 'Groups/0c73e7c3-47d5-4fcf-9fa4-6048a3d99ef7';
  const [decision, setDecision] = useState(defaultDecision);
  const [warned, setWarned] = useState(false);
  const onChangeDecision = dId => {
    setDecision(dId);
    if (window.matchMedia('(orientation: portrait)').matches && !warned) {
      alert('Inquries are best used in Landscape mode (horizontal)');
      setWarned(true);
    }
  };
  const inquirySx = {
    border: '2px solid #aaa',
    padding: '4px',
    margin: '4px',
    backgroundColor: '#eee'
  };
  return (
    <Box>
      <HStack backgroundColor='cyan.200'>
        <Text sx={{ fontWeight: 'bold' }}>Madlibs:</Text>
        <RadioGroup
          sx={{ border: '1px solid lightgray', padding: '0px 4px' }}
          onChange={onChangeDecision}
          defaultValue={defaultDecision}
        >
          <HStack direction='row'>
            <Radio bg='#fff' value='Questions/3495f3bd-4c05-4dce-80c7-26dd9c91f9d2'>
              Name It
            </Radio>
            <Radio bg='#fff' value='Questions/5395cb6c-c2f6-4794-96b1-06464a94da87'>
              What is it?
            </Radio>
            <Radio bg='#fff' value='Questions/d8d8f9d7-b1e9-420b-aa0c-c1ccb62d6ecb'>
              What it does
            </Radio>
            <Radio bg='#fff' value='Questions/91da40a3-03db-48a1-a943-ca33a52f5e67'>
              Who benefits?
            </Radio>
            <Radio bg='#fff' value='Questions/eed624dc-c8bf-4b48-99f6-9f5ea5664c50'>
              What can they do?
            </Radio>
            <Radio bg='#fff' value='Questions/ba6ba03e-39c2-4d5d-a9d5-e541d4c5f1c3'>
              Important why?
            </Radio>
          </HStack>
        </RadioGroup>
      </HStack>
      <UserAuth unauthorized='signin'>
        <Text>
          <strong>Add</strong> your own proposed answers in <em>Suggest</em> at the bottom.{' '}
          <strong>Upvote</strong> those and others you want to amplify. <strong>Click</strong>{' '}
          proposed options for more detail.{' '}
          <Text>
            <strong>First-timers probably need to reload the page</strong> before voting.
          </Text>
        </Text>
        <Text>
          <strong>
            Use the <em>Sort by</em> options
          </strong>{' '}
          to see latest, find alphabetically, ...
        </Text>
        {decision && (
          <Box sx={inquirySx}>
            <OneDecision group_in={defaultGroup} decision_in={decision} />
          </Box>
        )}
      </UserAuth>
    </Box>
  );
}

export default Positioning;
