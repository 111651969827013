import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Text, Input } from '@chakra-ui/react';

function SelectorExtendable(props) {
  const cb = props.cb;
  const name = props.name;
  const key = 'madlibs|' + name;
  const listname = 'options_' + name;
  const [optionsList, setOptionsList] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (optionsList == null) {
      let current = localStorage.getItem(key) || '[]';
      setOptionsList(JSON.parse(current));
    }
  }, [key, optionsList]);

  const makeOptions = useCallback(() => {
    let opts = [];
    if (optionsList) {
      opts = optionsList.map(one => {
        return (
          <option value={one} key={one}>
            {one}
          </option>
        );
      });
    }

    setOptions(opts);
  }, [optionsList]);

  const doSelect = e => {
    const selected = e.target.value;
    let current = localStorage.getItem(key) || '[]';
    current = JSON.parse(current);
    let theSet = new Set(current);
    theSet.add(selected);
    const as_array = Array.from(theSet);
    as_array.sort();
    localStorage.setItem(key, JSON.stringify(as_array));
    setOptionsList(as_array);
    if (cb) cb({ name, selected });
  };
  const showOptions = () => {
    makeOptions();
  };

  return (
    <Text
      sx={{
        display: 'inline',

        height: '12px',
        width: '100%'
      }}
    >
      <Input
        sx={{ width: '300px', border: '2px solid grey' }}
        name={props.name}
        list={listname}
        onBlur={doSelect}
        onFocus={showOptions}
      />

      <datalist id={listname} onSelect={doSelect}>
        {options}
      </datalist>
    </Text>
  );
}

export default SelectorExtendable;
